// jshint strict:false, undef:false, unused:false, esnext:true

// INIT
// --------------------------------------------------------------------------
embedVimeo();

// Select active navigation
var slug = window.location.pathname.split('-')[0],
	btns = document.querySelectorAll('.navbar-nav > li > a[href="' + window.location.pathname + '"]');

for (var i = 0; i < btns.length; i++) {
	btns[i].parentElement.classList.add("active");
}

if (slug === '/products') {
	document.querySelector('#navbar .btn-products').classList.add("active");
}

// Data Masking
document.querySelectorAll("[type=tel]").forEach(el => {
	IMask(el, {mask: '(000) 000-0000'});
});

// Crema Checkbox Replacement
custom_controls();

// Crema Captcha
captcha('[data-captcha]');

//// Enable Ajax on Formcarry Forms
//ajaxFormInit();


// GRANDPARENT'S CLUB SLIDER
// --------------------------------------------------------------------------
var vendors = [
	{
		img: "/assets/img/grandparents-club/promo-1-@2x.jpg",
		alt: "Bahamas Medical Learning Center",
		url: "http://bmlcnow.org/"
	},
	{
		img: "/assets/img/grandparents-club/promo-2-@2x.jpg",
		alt: "VL Cosmetics: Wake up to Make Up",
		url: "https://www.facebook.com/VL-Cosmetics-226448681515892"
	},
	{
		img: "/assets/img/grandparents-club/promo-3-@2x.jpg",
		alt: "Glabella: Forever Smooth Hair Removal",
		url: "https://www.facebook.com/glabella242/"
	},
	{
		img: "/assets/img/grandparents-club/promo-4-@2x.jpg",
		alt: "Live By Olivia: Recipes. Lifestyle Hacks. Reviews.",
		url: "https://www.lifebyolivia.net/"
	},
	{
		img: "/assets/img/grandparents-club/promo-5-@2x.jpg",
		alt: "Abaco Neem: Essential Oils that Help the Body Heal",
		url: "https://www.abaconeem.com/"
	},
	{
		img: "/assets/img/grandparents-club/promo-6-@2x.jpg",
		alt: "The Facebar: Skin Care Service",
		url: "https://www.facebook.com/thefacebar242/"
	},
    {
		img: "/assets/img/grandparents-club/promo-7-@2x.jpg",
		alt: "Brown's Mobile Car Wash",
		url: "https://www.facebook.com/" 
	},
    {
		img: "/assets/img/grandparents-club/promo-8-@2x.jpg",
		alt: "Main Sales Express Shipping",
		url: "https://www.facebook.com/"
	},
    {
		img: "/assets/img/grandparents-club/promo-9-@2x.jpg",
		alt: "Utia's EMT: Pick-up &amp; Drop-off Service",
		url: "https://www.facebook.com/"
	},
    {
		img: "/assets/img/grandparents-club/promo-10-@2x.jpg",
		alt: "Nassau Professional Disinfectant Services",
		url: "https://www.facebook.com/"
	},
    {
		img: "/assets/img/grandparents-club/promo-11-@2x.jpg",
		alt: "Barrows Beez-ness: Bees and Termite Removal",
		url: "https://www.facebook.com/"
	}
];

vendors.sort(() => Math.random() - 0.5);

// Split into two arrays
var vendors1 = vendors.slice(0, vendors.length / 2),
	vendors2 = vendors.slice(vendors.length / 2, vendors.length);

function insertSlide(selector, vendor) {	
	var el = document.querySelector(selector), template = ` 
	<a href="${vendor.url}" target="_blank" class="thumbnail">
		<img src="${vendor.img.replace('-@2x', '')}" srcset="${vendor.img} 2x" class="img-responsive" alt="${vendor.alt}">
	</a>`;
	
	if (el) {
		el.insertAdjacentHTML("beforeend", template);
	}
}

vendors1.forEach(vendor => {
	insertSlide("#promo-slider-1", vendor);
});

vendors2.forEach(vendor => {
	insertSlide("#promo-slider-2", vendor);
});

$("#promo-slider-1, #promo-slider-2").slick({
	fade: true,
	infinite: true,
	speed: 0,
	slidesToShow: 1,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4000
});